import React from 'react';
import Layout from '~/components/layout/layout/layout';
import SEO from '~/components/common/SEO/SEO';
import FourOFour from '~/components/pages/404/four-o-four';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Page not found" />
    <FourOFour />
  </Layout>
);

export default NotFoundPage;
