import React from 'react';
import FullPageCentered from '../../layout/full-page-centered/full-page-centered';
import AnimatedLinkButton from '../../input/AnimatedLinkButton/AnimatedLinkButton';

const FourOFour = () => {
  return (
    <FullPageCentered backgroundColor="red">
      <h2>You lost?</h2>
      <p>This page does not exist</p>

      <AnimatedLinkButton to="/" color="black" className="mt-8">
        Go back home
      </AnimatedLinkButton>
    </FullPageCentered>
  );
};

export default FourOFour;
